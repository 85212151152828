import Vue from 'vue'
import Vuex from 'vuex'

import { Loading,  QSpinnerFacebook,  } from 'quasar'
import { Notify } from 'quasar'

Vue.use(Vuex)

// var URL = 'http://localhost:5089/'
var URL = 'https://server-sippadu.konaweselatankab.go.id/';
// var URL = 'https://server-sapakonsel.konaweselatankab.go.id/';

export default new Vuex.Store({
  state: {
    coordinat : {
      lat:-4.034694, 
      lng: 122.484263
    },
    btn : {
      add : false,
      edit : false,
      remove : false,
    },
    url : {
      URL_APP : URL,
      
      URL_DASHBOARD : URL + 'api/v1/dashboard/',

      URL_DM_REGISTER : URL+'api/v1/dm_registrasi/',
      URL_DM_MENU : URL+'api/v1/dm_menuList/',
      URL_DM_KLP_USERS : URL+'api/v1/dm_kelompokUsers/',
      kertasKerja : URL + 'api/v1/kertasKerja/',
      checkAuth : URL + 'api/v1/checkAuth/',
      URL_simpeg_instansi : URL + 'api/v1/simpeg_instansi/',
      URL_simpeg_unit_kerja : URL + 'api/v1/simpeg_unit_kerja/',
      URL_simpeg_biodata: URL + 'api/v1/simpeg_biodata/',
      URL_kegiatan: URL + 'api/v1/kegiatan/',
      URL_lapor: URL + 'api/v1/lapor/',
      URL_masterJenis: URL + 'api/v1/masterJenis/',
      URL_Berita : URL + 'api/v1/server_berita/',
      URL_Perda : URL + 'api/v1/server_perda/',
      URL_Perkada : URL + 'api/v1/server_perkada/',

      URL_Laporan : URL + 'api/v1/server_laporan/',
      URL_Beranda : URL + 'api/v1/server_beranda/',
      

      
    },

    // ======================================  AUTOCOMPLETE ====================================
    list_master_type : [],
    list_master_direktori : [],
    list_anggota : [],
    // ======================================  AUTOCOMPLETE ====================================
    
    list_klp : [],
    list_master_jenisaudit : [],
    list_contoh_autocomplete : [],
    list_dir : [],
    list_instansi : [],
    list_unit_kerja : [],
    
    listOPD: [],
    list_bulan: [],
    // ======================================  AUTOCOMPLETE ====================================

    // ======================================  KERTAS KERJA ====================================

    list_bku : [],
    list_bkux : [],
    list_dana : [],
    list_permintaan : [],
    list_jabatan : [],
    list_adm : [],
    list_masterPajak : [],


    // ======================================  KERTAS KERJA ====================================

    list_menu : null,
    aksesMenu : {},

    page_first: 1,
    page_last: 0,
    cari_value: "",
    cek_load_data : true,
    btn : {
      addx : true,
      updatex : true,
      deletex : true,
  
    },

    type : [
      {id : 0, uraian : 'Single Menu'},
      {id : 1, uraian : 'Multy Menu'}
    ],

    
  master_type :[
    {id :1, uraian : 'Pribadi'},
    {id :2, uraian : 'Instansi'},
    {id :3, uraian : 'Publik'},
  ],

  
  master_status :[
    {id :0, uraian : 'Proses'},
    {id :1, uraian : 'Dipublikasi'},
    {id :2, uraian : 'Direvisi'},
  ]
    
  },
  mutations: {
    listJeniskategorilokasi(state){

      fetch(state.url.URL_MasterKategoriLokasi, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
        }
      })
        .then(res => res.json())
        .then(res_data => {
          state.list_MasterKategoriLokasi = res_data;
      });

    },

    getStorage(state){
      var get_profile = JSON.parse(localStorage.profile);
      state.unit_kerja = get_profile.profile.unit_kerja; 
    },
    shoWLoading(){
      const spinner = typeof QSpinnerFacebook !== 'undefined'
        ? QSpinnerFacebook // Non-UMD, imported above
        : Quasar.components.QSpinnerFacebook // eslint-disable-line


      Loading.show({
        spinner,
        spinnerColor: 'orange',
        spinnerSize: 140,
        backgroundColor: 'purple',
        // message: 'Loading... Tunggu beberapa saat, system sedang menyesuaikan akun anda..!',
        // messageColor: 'white'
      })
    },
    hideLoading(){
      Loading.hide()
    },
    shoWNotify(state, message, color, icon){
      Notify.create({
        message: message,
        color: color,
        position : 'top-right',
        icon:icon
      })
    },
  },

  notifAdd(state, n) {
    Swal.fire({
      icon: 'success',
      title: 'Sukses '+n+' data',
      showConfirmButton: false,
      timer: 1200
      // footer: '<a href>Why do I have this issue?</a>'
    })
  },

  actions: {
  },
  modules: {
  }
})
