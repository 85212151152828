
// import Logo from '~/components/Logo.vue'
// import VuetifyLogo from '~/components/VuetifyLogo.vue'
import FETCHING from "../library/fetching";


export default {
  components: {
    // Logo,
    // VuetifyLogo
  },
  data() {
    return {
     
      laporan_masuk : '',
      laporan_selesai : '',
      laporan_proses : '',
      laporan_dikembalikan : '',

      list_data : [],
      listChart : null,
      list_opd_upload : [],
      FETCHING : FETCHING,
    }
  },

  methods: {


    getJumlahMasuk : function(){
        fetch(this.$store.state.url.URL_Beranda + "laporan", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
        })
            .then(res => res.json())
            .then(res_data => {
              // console.log(res_data);
              this.laporan_masuk = res_data.data[0].laporan_masuk;
        });
      },
    getJumlahProses : function(){
        fetch(this.$store.state.url.URL_Beranda + "proses", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
        })
            .then(res => res.json())
            .then(res_data => {
              // console.log(res_data);
              this.laporan_proses = res_data.data[0].laporan_proses;
        });
      },

      getJumlahSelesai : function(){
        fetch(this.$store.state.url.URL_Beranda + "selesai", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
        })
            .then(res => res.json())
            .then(res_data => {
              // console.log(res_data);
              this.laporan_selesai = res_data.data[0].laporan_selesai;
              // console.log(jumlah_pelaku);
        });
      },

      getJumlahTolak : function(){
        fetch(this.$store.state.url.URL_Beranda + "dikembalikan", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
        })
            .then(res => res.json())
            .then(res_data => {
              // console.log(res_data);
              this.laporan_dikembalikan = res_data.data[0].laporan_dikembalikan;
              // console.log(jumlah_pelaku);
        });
      },

      getPerda : function(){
      fetch(this.$store.state.url.URL_Beranda + "anak", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
      })
          .then(res => res.json())
          .then(res_data => {
            console.log(res_data);

            var arr_jenis = [];
            var arr_anak = [];

            for(var i = 0; i<res_data.panjang; i++){
              arr_jenis.push(res_data.data[i].nama_jenis);
            }

            for(var i = 0; i<res_data.panjang; i++){
              arr_anak.push(res_data.data[i].jumlah);
            }

            this.AnakPie(arr_jenis, arr_anak);


      });
    },    

    chart1 : function(chartku,dn) {
      const chart = Highcharts.chart(chartku, {
          chart: {
              borderColor: '#efefef',
              borderWidth: 2,
          },
          title: {
              text: '-'
          },
          subtitle: {
              text: ' '
          },
          xAxis: {
              categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
          },
          series: [{
              type: 'column',
              colorByPoint: true,
              data: [dn.jan, dn.feb, dn.mar, dn.apr, dn.mei, dn.jun, dn.jul, dn.agu, dn.sep, dn.okt, dn.nov, dn.des],
              showInLegend: false
          }]
      });


    },

    asyncFunc : async function(){
      this.listChart = await this.FETCHING.dashboardPost('dashboardChart');
     
      this.chart1('chart1', this.listChart);

    },



  },

  mounted () {

    this.getJumlahMasuk();    
    this.getJumlahProses();    
    this.getJumlahSelesai();
    this.getJumlahTolak();

    this.asyncFunc();

  },
}
