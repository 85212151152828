import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
// import Lapor from '../views/Lapor.vue'

Vue.use(VueRouter);





function loggedInRedirectDashboard(to, from, next) {
  if (localStorage.token) {
    next('/');
  } else {
    next();
  }
}

function isLoggedIn(to, from, next) {
  if (localStorage.token) {
    next();
  } else {
    next('/login');
  }
}










  const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/auth/login.vue'),
    beforeEnter: loggedInRedirectDashboard,
  },



  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: isLoggedIn,
  },


 
  {
    path: '/menuList',
    name: 'menuList',
    component: () => import('../views/dataMaster/menuList.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/klpUsers',
    name: 'klpUsers',
    component: () => import('../views/dataMaster/klpUsers.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/registrasi',
    name: 'registrasi',
    component: () => import('../views/dataMaster/registrasi.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/masterJenis',
    name: 'masterJenis',
    component: () => import('../views/dataMaster/masterJenis.vue'),
    beforeEnter: isLoggedIn,
  },

  {
    path: '/laporan',
    name: 'laporan',
    component: () => import('../views/laporan.vue'),
    beforeEnter: isLoggedIn,
  },

  {
    path: '/berita',
    name: 'berita',
    component: () => import('../views/berita.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/perda',
    name: 'perda',
    component: () => import('../views/perda.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/perkada',
    name: 'perkada',
    component: () => import('../views/perkada.vue'),
    beforeEnter: isLoggedIn,
  },



]

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

export default router
