const Swal = require('sweetalert2')
const notifDelete = async ()=>{

  return new Promise(resolve=>{

    Swal.fire({
      title: 'Apakah anda yakin utk menghapus?',
      text: "Pilih button hapus untuk menghapus data ini!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Hapus!'
    }).then((result) => {
      if (result.isConfirmed) {
       resolve('');
      }
    })
  })
}

const notifApprove = async ()=>{

  return new Promise(resolve=>{

    Swal.fire({
      title: 'Apakah anda yakin untuk menyetujui data?',
      text: "Pilih button Approve untuk menyetujui data ini!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Approve!'
    }).then((result) => {
      if (result.isConfirmed) {
       resolve('');
      }
    })
  })
}



const replaceStr = (data) =>{
  var res = data.toString().replace(/\/|-| |@/g,'_X_');
    return res
}


const ArrToObj = (data) =>{
  var obj = data.reduce(function(acc, cur, i) {
    acc[replaceStr(cur.route)] = {
      readx : cur.readx,
      updatex : cur.updatex,
      deletex : cur.deletex,
      addx : cur.addx,
    };
    return acc;
  }, {});

  return obj
}

const tglConvert = (tgl) => {

  var date = new Date(tgl);
  var getBulan = date.getMonth() + 1; var bulan = '';
  if (getBulan == '1') {bulan = 'Jan'}
  else if(getBulan == '2') {bulan = 'Feb'}
  else if(getBulan == '3') {bulan = 'Mar'}
  else if(getBulan == '4') {bulan = 'Apr'}
  else if(getBulan == '5') {bulan = 'Mei'}
  else if(getBulan == '6') {bulan = 'Juni'}
  else if(getBulan == '7') {bulan = 'Juli'}
  else if(getBulan == '8') {bulan = 'Agust'}
  else if(getBulan == '9') {bulan = 'Sept'}
  else if(getBulan == '10') {bulan = 'Okt'}
  else if(getBulan == '11') {bulan = 'Nov'}
  else if(getBulan == '12') {bulan = 'Des'}

  // console.log(date)

  return date.getDate() + " " + bulan + " " + date.getFullYear();
}

const rupiah = (number)=>{
  return new Intl.NumberFormat('id-ID', 
  
  {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  }
  
  ).format(number);
}





const statusNotice = (data) => {

  if (data == 0) {
    return {
      color : 'orange',
      icon : 'hourglass_empty',
      btn : false
    }
  }
  else if (data == 1) {
    return {
      color : 'green',
      icon : 'bookmark_added',
      btn : true
    }
  }
  else{
    return {
      color : 'red',
      icon : 'more',
      btn : false
    }
  }
}





module.exports = {

  notifDelete : notifDelete,
  notifApprove : notifApprove,
  replaceStr : replaceStr,
  ArrToObj : ArrToObj,
  tglConvert : tglConvert,
  statusNotice : statusNotice,
  rupiah : rupiah,

}
