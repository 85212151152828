// import Swal from 'sweetalert2'
const Swal = require('sweetalert2')
var DataStore = require('../store');
var store = DataStore.default;
var storex =store.state


const getUnitKerja = async (instansi)=>{
  return new Promise(resolve=>{
    fetch(storex.url.URL_simpeg_unit_kerja + "list", {
        method: "POST",
        headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
        },
        body: JSON.stringify({
          instansi: instansi
        })
      })
        .then(res => res.json())
        .then(res_data => {
          // console.log(res_data)
          resolve(res_data)

    });
  })
}

const getUnitKerjaAutocomplete = async (unit_kerja)=>{
  return new Promise(resolve=>{
    fetch(storex.url.URL_simpeg_unit_kerja + "getUnitKerjaAutocomplete", {
        method: "POST",
        headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
        },
        body: JSON.stringify({
          unit_kerja: unit_kerja
        })
      })
        .then(res => res.json())
        .then(res_data => {
          // console.log(res_data)
          resolve(res_data)

    });
  })
}



  const getMasterMenu = async ()=>{
    return new Promise(resolve=>{
      fetch(storex.url.URL_DM_KLP_USERS + "listAdd", {
          method: "GET",
          headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
          }
        })
          .then(res => res.json())
          .then(res_data => {
            // console.log(res_data)
            resolve(res_data)
  
      });
    })
  }
  
  // ini buat ambil menu pada saat edit data
  const postMasterMenu = async (id)=>{
    return new Promise(resolve=>{
      fetch(storex.url.URL_DM_KLP_USERS + "listEdit", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
            menu_klp_id: id,
          })
        })
          .then(res => res.json())
          .then(res_data => {
            // console.log(res_data)
            resolve(res_data)
  
      });
    })
  }
  
  

  const postMasterKlpMenu = async ()=>{
    return new Promise(resolve=>{
      fetch(storex.url.URL_DM_KLP_USERS + "list", {
          method: "GET",
          headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
          }
        })
          .then(res => res.json())
          .then(res_data => {
            // console.log(res_data)
            resolve(res_data)
  
      });
    })
  }
  
  
  const postMasterMenuGetSideBar = async (id)=>{
    return new Promise(resolve=>{
      fetch(storex.url.URL_DM_KLP_USERS + "listSidebar", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
            side_bar : true,
            menu_klp_id: id,
          })
        })
          .then(res => res.json())
          .then(res_data => {
            // console.log(res_data)
            resolve(res_data)
  
      });
    })
  }



  // ====================================== CONTOH AUTOCOMPLETE ====================================
  const getType = (val)=>{

      fetch(storex.url.checkAuth + "tipe_akses", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
            val : val,
          })
        })
          .then(res => res.json())
          .then(res_data => {
            
            storex.list_master_type = res_data
      });
  }

  const getUnitKerjaa = (cari)=>{

      fetch(storex.url.URL_simpeg_unit_kerja + "list2", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
            cari : cari,
          })
        })
          .then(res => res.json())
          .then(res_data => {
            
            storex.list_unit_kerja = res_data
      });
  }



  
  const getADM = (cari)=>{

      fetch(storex.url.kertasKerja + "adm", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
            cari : cari,
          })
        })
          .then(res => res.json())
          .then(res_data => {
            // console.log(res_data);
            storex.list_adm = res_data
      });
  }


  const getKlp = (val)=>{

      fetch(storex.url.checkAuth + "menu_klp", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
            val : val,
          })
        })
          .then(res => res.json())
          .then(res_data => {
            
            storex.list_klp = res_data
      });
  }
  const getBku = (val, unit_kerja_id)=>{

      fetch(storex.url.kertasKerja + "bku", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
            val : val,
            unit_kerja_id : unit_kerja_id
          })
        })
          .then(res => res.json())
          .then(res_data => {
            
            storex.list_bku = res_data
      });
  }
  const getBkux = (val, unit_kerja_id)=>{

      fetch(storex.url.kertasKerja + "bkux", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
            val : val,
            unit_kerja_id : unit_kerja_id
          })
        })
          .then(res => res.json())
          .then(res_data => {
            
            storex.list_bkux = res_data
      });
  }

  const getAnggota = (val)=>{

      fetch(storex.url.checkAuth + "anggota", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
            val : val,
          })
        })
          .then(res => res.json())
          .then(res_data => {
            
            storex.list_anggota = res_data
      });
  }


  const getDir = (val)=>{

      fetch(storex.url.URL_direktori + "listdata", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
            val : val,
          })
        })
          .then(res => res.json())
          .then(res_data => {
            
            storex.list_dir = res_data
      });
  }

  // ====================================== CONTOH AUTOCOMPLETE ====================================
  const getContohAtocomplete = (val)=>{

    fetch(storex.url.checkAuth + "autocomplete_db", {
        method: "POST",
        headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
        },
        body: JSON.stringify({
          val : val,
        })
      })
        .then(res => res.json())
        .then(res_data => {
          
          storex.list_contoh_autocomplete = res_data
    });
}
  // ====================================== CONTOH AUTOCOMPLETE ====================================

  const postDirektori = async (unit_kerja_id, uraian)=>{
    return new Promise(resolve=>{
      fetch(storex.url.URL_direktori + "list", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
            unit_kerja_id: unit_kerja_id,
            uraian : uraian
          })
        })
          .then(res => res.json())
          .then(res_data => {
            // console.log(res_data)
            resolve(res_data)
  
      });
    })
  }

  
async function FETCH_POST (url, body){
  return new Promise(resolve =>{
    fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        authorization: "kikensbatara " + localStorage.token
      },
      body: JSON.stringify(body)
    })
      .then(res => res.json())
      .then(res_data => {
        // console.log(res_data)
        resolve(res_data)
      });
  })
}

const dashboardPost = async (route)=>{
  return new Promise(resolve=>{
    fetch(storex.url.URL_Beranda + route, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
        },
        body: JSON.stringify()
      })
        .then(res => res.json())
        .then(res_data => {
          console.log(res_data)
          resolve(res_data)

    });
  })
}

const getDana = (val)=>{

  fetch(storex.url.kertasKerja + "dana", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        authorization: "kikensbatara " + localStorage.token
      },
      body: JSON.stringify({
        val : val,
      })
    })
      .then(res => res.json())
      .then(res_data => {
        
        storex.list_dana = res_data
  });
}

const getPermintaan = (val)=>{

  fetch(storex.url.kertasKerja + "permintaan", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        authorization: "kikensbatara " + localStorage.token
      },
      body: JSON.stringify({
        val : val,
      })
    })
      .then(res => res.json())
      .then(res_data => {
        
        storex.list_permintaan = res_data
  });
}
const getJabatan = (val)=>{

  fetch(storex.url.kertasKerja + "jabatan", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        authorization: "kikensbatara " + localStorage.token
      },
      body: JSON.stringify({
        val : val,
      })
    })
      .then(res => res.json())
      .then(res_data => {
        
        storex.list_jabatan = res_data
  });
}
const getBulan = (val)=>{

  fetch(storex.url.kertasKerja + "bulan", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        authorization: "kikensbatara " + localStorage.token
      },
      body: JSON.stringify({
        val : val,
      })
    })
      .then(res => res.json())
      .then(res_data => {
        
        storex.list_bulan = res_data
  });
}

const getPPH = (val)=>{

  fetch(storex.url.kertasKerja + "pph", {
      method: "POST",
      headers: {
        "content-type": "application/json",
        authorization: "kikensbatara " + localStorage.token
      },
      body: JSON.stringify({
        val : val,
      })
    })
      .then(res => res.json())
      .then(res_data => {
        
        storex.list_pph = res_data
  });
}

const POST_DATA = async (url, data) => {  
  return new Promise(resolve =>{
      fetch(url, {
          method: "POST",
          headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify(data)
      })
      .then(res_data => { 
          resolve(res_data)
      })
  })
}




  


module.exports = {

    getMasterMenu : getMasterMenu,
    postMasterMenu : postMasterMenu,
    postMasterMenuGetSideBar : postMasterMenuGetSideBar,
    postMasterKlpMenu : postMasterKlpMenu,
    getType : getType,
    getKlp : getKlp,
    getAnggota : getAnggota,
    getDir : getDir,
    getContohAtocomplete : getContohAtocomplete,
    postDirektori : postDirektori,
    getUnitKerjaAutocomplete : getUnitKerjaAutocomplete,
    FETCH_POST : FETCH_POST,
    getUnitKerja : getUnitKerja,
    dashboardPost : dashboardPost,
    getBulan : getBulan,

    getDana : getDana,
    getPermintaan : getPermintaan,
    getJabatan : getJabatan,
    getBku : getBku,
    getBkux : getBkux,
    getPPH : getPPH,
    getUnitKerjaa : getUnitKerjaa,
    getADM : getADM,



    POST_DATA : POST_DATA, 
}